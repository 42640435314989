
import { computed, defineComponent, ref } from "vue";
import PlayerSelection from "@/components/PlayerSelection.vue";
import Game27 from "@/components/27/Game27.vue";
import Summary27 from "@/components/27/Summary.vue";
import { usePlayerStore } from "@/store/player";
import { usePrefs, SUMMARY_INGAME_OPTIONS } from "@/store/clientPreferences";
import { use27History } from "@/store/history27";

export default defineComponent({
  components: {
    PlayerSelection,
    Game27,
    Summary27,
  },
  async setup() {
    const playerStore = usePlayerStore();
    const preferences = usePrefs();
    const history = use27History();

    // await playerStore.loadAllPlayers();
    const all_players = computed(() =>
      playerStore.availablePlayers.filter(p => preferences.displayGuestSelection || !p.guest));
    const players = ref((await playerStore.getDefaultPlayers("twentyseven"))
      .map(({ value:{ id }}) => id));
    return {
      players,
      all_players,
      allowGuestSelection: computed(() => preferences.displayGuestSelection),
      date: ref((new Date()).toISOString().slice(0, 16)),
      ingameSummary: computed(() => preferences.twentyseven.ingameSummary),
      summaryEnabled: computed(() => preferences.twentyseven.ingameSummary.players !== "none"),
      summaryDisplay: computed({
        get: () => preferences.twentyseven.ingameSummary.players,
        set: val => preferences.$patch(state => state.twentyseven.ingameSummary = {
          ...state.twentyseven.ingameSummary,
          players: val,
        }),
      }),
      summaryPlayers: computed(() => {
        switch (preferences.twentyseven.ingameSummary.players) {
          default:
          case "none": return [];
          case "current": return [];//TODO
          case "playing":
            return history.summaryPlayers.filter(({ id }) => players.value.includes(id));
          case "common": return history.summaryPlayers.filter(({ guest }) => !guest);
          case "all": return history.summaryPlayers;
        }
      }),
      summaryPlayersOptions: computed(() => Object.entries(SUMMARY_INGAME_OPTIONS)
        .filter(([k, _v]) => {
          switch (k) {
            case "current": // Not yet implemented
              return false;
            case "all": // Non functional if guest display is disabled (identical to common)
              return preferences.displayGuestSummary;
            default:
              return true;
          }
        })),
      saveGamesInProgress: computed(() => preferences.saveGamesInProgress),
      clearGame: () => {
        window.sessionStorage.clear(); //TODO: only clear relevant?
        window.location.reload();
      },
    };
  },
});
