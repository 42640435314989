import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode(" Game ")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/history" }, {
        default: _withCtx(() => [
          _createTextVNode(" Past Games ")
        ]),
        _: 1
      })
    ]),
    (_openBlock(), _createBlock(_Suspense, null, {
      fallback: _withCtx(() => [
        _createTextVNode(" Loading... ")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }))
  ], 64))
}