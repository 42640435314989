
// import { getPlayerIds, iterPlayers, Player } from "@/util/player";
import { Player, usePlayerStore } from "@/store/player";
import { RowMetadata } from "@/utils/display";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    players: { type: Array as PropType<(Player | string)[]>, required: true },
    width: { type: Number, default: 1 },
    rows: { type: Array as PropType<RowMetadata[]>, required: true },
  },
  setup(props) {
    const playerStore = usePlayerStore();
    // const slotIds = computed(() => Object.keys(props.rows));
    return {
      // slotIds,
      onClick: (rowId: string, event: MouseEvent) => {
        let handler = props.rows.find(({ slotId }) => slotId === rowId)?.onClick;
        if (handler) {
          return handler(event);
        }
      },
      playersIter: computed(() =>
        props.players.map(p => typeof p === "object" ? p : playerStore.getPlayer(p))),
      playerIds: computed(() =>
        props.players.map(p => typeof p === "object" ? p.id : p)),
    };
  },
});
