
import { computed, ComputedRef, defineComponent, PropType } from "vue";
import { storeToRefs } from "pinia";
import PlayerTable from "@/components/PlayerTable.vue";
import SummaryTooltip from "./SummaryTooltip.vue";
import { Player, usePlayerStore } from "@/store/player";
import { summaryFields } from "@/games/27";
import { use27History } from "@/store/history27";
import { usePrefs } from "@/store/clientPreferences";

export default defineComponent({
  components: {
    PlayerTable,
    SummaryTooltip,
  },
  props: {
    players: { type: Array as PropType<Player[]>, required: true },
    filtered: { type: Array as PropType<string[]>, required: true },
    // games: { type: Array as PropType<Result27[] | null>, default: null },
    // scores: {
    //   type: Object as PropType<{ [k: string]: (PlayerGameResult27 | null)[] } | null>,
    //   default: null,
    // },
  },
  setup(props) {
    const playerStore = usePlayerStore();
    const { playerStats, scores, games } = storeToRefs(use27History());
    function computedPlayers<T>(f: (player: string) => T): ComputedRef<{ [K: string]: T }> {
      return computed(() => props.players.reduce((obj, { id }) => {
        obj[id] = f(id);
        return obj;
      }, {} as { [K: string]: T }));
    }
    const numGames = computedPlayers(p => scores.value[p].filter(s => s != null).length);
    const gameWinners = computed(() => games.value.reduce((acc, game) => {
      const winner = typeof game.winner === "string" ? game.winner : game.winner.tiebreak.winner!;
      if (!Object.hasOwn(acc, winner)) {
        acc[winner] = [];
      }
      acc[winner].push(Object.keys(game.game));
      return acc;
    }, {} as { [k: string]: string[][] }));
    const roundData = computed(() => props.players.reduce(
      ({ bestRate, bestPlayers }, { id: player }) => {
        const stats = playerStats.value[player];
        if (stats.num > 0) {
          const pBR = stats.roundData.favourites.games.hits / stats.num;
          if (pBR > bestRate) {
            bestRate = pBR;
            bestPlayers = [player];
          } else if (pBR === bestRate) {
            bestPlayers.push(player);
          }
        }
        return { bestRate, bestPlayers };
      },
      {
        bestRate: 0,
        bestPlayers: [] as string[],
      },
    ));
    const statLimits = computed(() => Object.values(playerStats.value).reduce(
      (acc, s) => {
        for (const k of Object.keys(acc) as (keyof typeof acc)[]) {
          acc[k].min = Math.min(acc[k].min, s[k]);
          acc[k].max = Math.max(acc[k].max, s[k]);
        };
        return acc;
      }, {
        best: { min: 1288, max: -394 },
        worst: { min: 1288, max: -394 },
        mean: { min: 1288, max: -394 },
        fn: { min: 0, max: 0 },
        cliffs: { min: 0, max: 0 },
        cliffR: { min: 0, max: 0 },
        dd: { min: 0, max: 0 },
        ddR: { min: 0, max: 0 },
        hans: { min: 0, max: 0 },
        goblins: { min: 0, max: 0 },
        piranhas: { min: 0, max: 0 },
        jesus: { min: 0, max: 0 },
        allPos: { min: 0, max: 0 },
        farDream: { min: 0, max: 0 },
        bestHits: { min: 0, max: 0 },
        worstHits: { min: 0, max: 0 },
        meanHits: { min: 0, max: 0 },
      }));
    const rowMeta = [...summaryFields];
    for (let r = 1; r <= 20; r++) {
      rowMeta.push({
        label: r.toString(),
        slotId: r.toString(),
        additionalClass: ["specificRound"],
      });
    }
    const asFixed = (num: number, precision = 2): number => parseFloat(num.toFixed(precision));
    const asRate = (player: string, num: number, precision = 2): string =>
      asFixed(num / numGames.value[player] * 100, precision) + "%";
    return {
      rowMeta,
      numGames,
      isFiltered: computed(() =>
        props.filtered.length > 0 && props.players.some(p => !props.filtered.includes(p.id))),
      filteredNames: computed(() => {
        const arr = props.filtered.map(p => playerStore.getName(p));
        switch (arr.length) {
          case 0:
            return "";
          case 1:
            return arr[0];
          default:
            const l = arr.length - 1;
            return `${arr.slice(0, l).join(", ")} and ${arr[l]} all`;
        }
      }),
      gameWinners,
      mostWins: computed(() => Object.entries(gameWinners.value).reduce(
        ({ all, all_count, all_rate, filtered, filtered_count, filtered_rate }, [p, wins]) => {
          const a_wins = wins.length;
          const f_wins = wins.filter(
            opponents => props.filtered.every(p => opponents.includes(p))).length;
          const num = numGames.value[p];
          const a = a_wins > all_count
            ? { all: [p], all_count: a_wins }
            : a_wins == all_count
              ? { all: [...all, p], all_count }
              : { all_count, all };
          const f = f_wins > filtered_count
            ? { filtered: [p], filtered_count: f_wins }
            : f_wins == filtered_count
              ? { filtered: [...filtered, p], filtered_count }
              : { filtered, filtered_count };
          return {
            ...a, ...f,
            all_rate: num ? Math.max(all_rate, a_wins / num) : all_rate,
            filtered_rate: num ? Math.max(filtered_rate, f_wins / num) : filtered_rate,
          };
        }, {
          all: [] as string[], all_count: 0, all_rate: 0,
          filtered: [] as string[], filtered_count: 0, filtered_rate: 0,
        })),
      statLimits,
      asFixed,
      asRate,
      playerStats,
      roundBest: roundData,
      roundFavouritesDisplay: computed(() => usePrefs().twentyseven.roundDisplay),
    };
  },
});
