
import { Player, usePlayerStore } from "@/store/player";
import { computed, customRef, defineComponent, PropType, Ref, ref } from "vue";

export default defineComponent({
  props: {
    legend: { type: String, required: true },
    available: { type: Array as PropType<(Player | string)[]>, required: true },
    modelValue: { type: Array as PropType<string[] | null>, default: null },
    allowGuests: { type: Boolean, default: false },
  },
  emits: [ "update:player", "update:modelValue" ],
  async setup(props, { emit }) {
    const playerStore = usePlayerStore();
    const allPlayers = computed(() =>
      props.available.map(p => typeof p === "object" ? p : playerStore.getPlayer(p)));
    const guests = ref(new Set<string>(props.modelValue !== null
      ? allPlayers.value.flatMap(({ id, guest }) => guest && props.modelValue!.includes(id)
        ? [id]
        : [])
      : []));
    const availablePlayers = computed(() =>
      allPlayers.value.filter(({ id, guest }) => !guest || guests.value.has(id)));
    const players = ref(props.modelValue
      ?? props.available.map(p => typeof p === "object" ? p.id :p));
    const sendUpdate = (playerId: string, checked: boolean): void => {
      emit("update:player", playerId, checked);
      emit("update:modelValue", players.value);
    };
    return {
      allPlayers,
      availablePlayers,
      players,
      date: ref((new Date()).toISOString().slice(0, 16)),
      onCheckboxChange: sendUpdate,
      guestGroups: computed(() =>
        allPlayers.value.reduce((acc, { id, name, guest, guestLabel }) => {
          if (guest && !guests.value.has(id)) {
            if (guestLabel) {
              if (!acc.groups.has(guestLabel)) {
                acc.groups.set(guestLabel, [[id, name]]);
              } else {
                acc.groups.get(guestLabel)!.push([id, name]);
              }
            } else {
              acc.ungrouped.push([id, name]);
            }
          }
          return acc;
        }, {
          groups: new Map<string, [string, Ref<string>][]>(),
          ungrouped: [] as [string, Ref<string>][],
        })),
      guests,
      addGuest: customRef((track, trigger) => ({
        get: () => {
          track();
          return "";
        },
        set: (pid: string) => {
          guests.value.add(pid);
          players.value.push(pid);
          sendUpdate(pid, true);
          trigger();
        },
      })),
    };
  },
});
